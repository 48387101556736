@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  background: $color-white;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @include breakpoint(md) {
    padding: 0;
    margin: 0 0 4px;
  }
}

.searchFormWrapper {
  width: 300px;

  @include breakpoint(md) {
    display: none;
  }
}

.cartSelectorWrapper {
  margin-left: auto;
  width: 400px;

  @include breakpoint(md) {
    width: 100%;
    border-bottom: 1px solid #dbdee4;
  }
}

.actionMenuWrapper {
  margin-left: 24px;
  display: flex;
  justify-content: space-between;

  @include breakpoint(md) {
    font-size: 12px;
    line-height: 120%;
    width: 100%;
    padding: 12px 8px 12px 4px;
    margin: 0;
  }
}

.cartSelectorOption {
  > span {
    color: $color-secondary;
  }
}

.confirmationModal {
  width: 100%;
  padding: 0 10px;
}

.cartActionsLabel {
  color: $color-secondary;
  display: flex;
  align-items: center;

  svg {
    margin: 0 8px;
  }

  @include breakpoint(md) {
    color: $color-text;
    font-size: 12px;
    line-height: 120%;
  }
}

.cartActionsItemLabel {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  @include breakpoint(md) {
    font-weight: 500;
  }

  svg {
    @include breakpoint(md) {
      width: 16px;
      height: 20px;
    }
    margin-right: 4px;
  }
}

.confirmationModalActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}

.removePositions {
  border: unset;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;

  svg {
    margin-right: 7px;
    height: 17px;
  }
}
