@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  max-width: 1920px;
  width: 100%;
  aspect-ratio: 2.56;
  overflow: hidden;
  margin: 0 auto;

  @include breakpoint(lg) {
    margin: 0 -25px 15px -25px;
    width: calc(100% + 50px);
  }

  @include breakpoint(sm) {
    aspect-ratio: 1;
  }
}

.slider {
  position: relative;

  .slide {
    padding-bottom: unset !important;
    height: unset !important;
    > div {
      position: initial;
    }
    img,
    a {
      display: block;
      width: 100%;
    }

    cursor: pointer;

    .slideLink {
      display: block;

      p {
        margin: 0;
      }
    }
  }

  .sliderLinks {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .dot {
      width: 10px;
      height: 10px;
      background-color: $color-white;
      border-radius: 5px;
    }

    button {
      white-space: nowrap;
      margin: 0 5px;
      border: none;
      background: transparent;
      font-weight: 400;
      font-size: 16px;
      color: $color-label;

      &:disabled {
        .dot {
          width: 20px;
          background-color: $color-primary;
        }
      }
    }
  }

  .arrows {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translate(0, -50%);
    padding: 0 10%;

    @include breakpoint(sm) {
      padding: 0 26px;
    }

    button {
      background-color: transparent;
      border: unset;

      @include breakpoint(sm) {
        svg {
          height: 40px;
          width: 20px;
        }
      }

      &:first-child {
        transform: rotate(180deg);
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.title {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: $color-text-dark;
  text-decoration: none;
}

.newsWrapper {
  width: 100%;
  margin-top: 24px;
}

.sliderPlaceholder {
  max-width: 1920px;
  width: 100%;
  aspect-ratio: 2.56;
  background-color: #e9e9e9;

  @include breakpoint(lg) {
    width: 100vw;
    aspect-ratio: 2.56;
    margin: 0 -25px 15px -25px;
  }

  @include breakpoint(sm) {
    aspect-ratio: 1;
  }
}
