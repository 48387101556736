@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  margin-top: 55px;
  @include breakpoint(sm) {
    margin-top: 15px;
  }
  > div {
    @include breakpoint(sm) {
      margin-bottom: 15px;
    }

    &:last-of-type {
      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }
  }

  & > div:not(.extended):nth-child(n + 2) {
    table {
      thead {
        th {
          visibility: hidden;
          height: 0;
          padding: 0;

          span {
            display: none;
          }
        }
      }
    }
  }

  .extended {
    border-bottom: 1px solid rgba($color: $color-table-border, $alpha: 0.6);

    table {
      width: 100%;

      tr th:first-child {
        min-width: 140px;
      }

      td:nth-child(4n + 3),
      td:nth-child(4n + 4) {
        background-color: rgba(239, 234, 232, 0.29);
      }
    }

    &:nth-child(n + 2) {
      table {
        thead {
          th {
            visibility: hidden;
            height: 0;
            padding: 0;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  table {
    margin: 0 auto;
    @include breakpoint(sm) {
      width: 100%;
      margin-top: 30px;
    }

    &:first-child {
      tr:last-child {
        td {
          border-bottom: transparent;
          @include breakpoint(sm) {
            border-bottom: 1px solid rgba($color: $color-table-border, $alpha: 0.6);
            padding-bottom: 0;
          }
        }
      }
    }
    &:first-child {
      tr:first-child {
        td {
          @include breakpoint(sm) {
            padding-top: 0;
          }
        }
      }
    }

    th {
      position: relative;
      width: 14%;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: $color-text-dark;
      background-color: transparent;
      border-bottom: 1px solid $color-secondary-border;
      text-transform: uppercase;
      @include breakpoint(sm) {
        font-size: 10px;
      }
    }

    tr {
      td:not(:first-child) {
        border-bottom: 1px solid rgba($color: $color-table-border, $alpha: 0.6);
        font-size: 14px;
      }
      td {
        @include breakpoint(sm) {
          border-bottom: 1px solid rgba($color: $color-table-border, $alpha: 0.6);
          font-size: 10px !important;
        }
      }
    }

    td {
      padding: 12px 8px;

      @include breakpoint(sm) {
        font-size: 10px;
        padding: 2px 0 0;
      }
    }

    & + table {
      thead th {
        padding: 0;

        span {
          display: none;
        }
      }
    }

    thead th {
      @include breakpoint(sm) {
        vertical-align: bottom;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        @include breakpoint(sm) {
          padding-bottom: 5px;
          font-weight: 700;
        }
      }
      &:nth-child(4) {
        @include breakpoint(sm) {
          position: relative;
        }
        span > span {
          @include breakpoint(sm) {
            width: 100%;
            font-weight: 600;
            position: absolute;
            left: -50%;
            transform: translate(50%, 0);
            top: -15px;
            display: flex;
            justify-content: center;
            white-space: nowrap;
          }

          @media screen and (max-width: 590px) {
            top: 0;
          }

          @media screen and (max-width: 510px) {
            white-space: unset;
            top: -15px;
          }
        }
      }
      &:nth-child(6) {
        @include breakpoint(sm) {
          min-width: 80px;
        }
      }
    }

    tbody td {
      &:nth-child(6) {
        input {
          @include breakpoint(sm) {
            margin: 0;
            flex: unset;
            width: 100%;
            font-size: 10px;
            border: 0;
          }
        }
      }
    }
  }

  .priceExtended {
    > div {
      margin-right: 0;
    }
  }

  .counter {
    button {
      width: 36px;
      height: 31px;

      @include breakpoint(sm) {
        width: 36px;
        height: 36px;
      }
    }

    input {
      width: 64px;

      @include breakpoint(sm) {
        width: 36px;
        height: 36px;
        min-width: 25px;
        max-width: 36px;
      }
    }
  }

  .collapseTitle {
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;

    span {
      text-transform: none;
      font-weight: 400;

      @include breakpoint(sm) {
        max-width: 93px;
        display: inline-block;
        text-wrap: wrap;
        padding-left: 3px;
      }
    }
  }

  .availabilityTitle {
    position: absolute;
    transform: translate(-50%, -30px);
    width: 100px;
  }

  .availability {
    > div {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        border-bottom: 1px solid rgba(224, 224, 224, 0.6);
      }
    }
  }

  .extendedQty {
    button {
      background: transparent;
    }
  }

  .promoPrice {
    span {
      display: block;
      font-size: 13px;

      &:first-child {
        text-decoration: line-through;
      }

      &:nth-child(2) {
        color: $color-red;
      }

      @include breakpoint(sm) {
        font-size: 10px;
      }
    }

    br {
      display: none;
    }

    @include breakpoint(sm) {
      flex-direction: column;
    }
  }
}
