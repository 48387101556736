@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
  > button {
    padding: 10px 10px;
    letter-spacing: 1px;

    .label {
      svg {
        font-size: 20px;
        margin-right: 12px;
      }
    }
  }
}

// h3
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: $color-text-light;
  margin: 0;

  @include breakpoint(sm) {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
