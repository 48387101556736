@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;

  .checkbox {
    > span > span {
      padding: 3px;
    }
  }
}

.searchWrapper {
  margin: 16px 0 0 0;
}

.searchResult {
  font-size: 12px;

  button {
    background-color: transparent;
    border: unset;
    color: $color-red;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
  }
}

.search {
  display: flex;

  input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid $color-gray-border;

    &:focus {
      border: 1px solid darken($color: $color-gray-border, $amount: 10%);
      outline: none;
    }
  }

  button {
    background-color: transparent;
    border: 1px solid $color-gray-border;
    border-left: unset;
    padding: 0 12px;
    cursor: pointer;

    @include breakpoint(sm) {
      padding: 0 15px;
    }

    svg {
      color: $color-secondary;
      font-size: 17px;
    }
  }
}

.noResults {
  font-size: 12px;
}

.content {
  max-height: 180px;
  overflow-y: auto;
  margin-top: 24px;

  > div {
    padding-left: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 6px;

    @include breakpoint(sm) {
      padding-left: 8px;
    }
  }
}

.checkbox {
  margin-right: 5px;
}

.loaderWrapper {
  margin-left: 20px;
}

.filtersWrapper {
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  .filter {
    flex: 1;
    * {
      white-space: initial;
    }

    @include breakpoint(sm) {
      padding: 14px 0;

      > div > div:first-child span {
        font-size: 18px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-primary-background;
      }

      > div {
        margin-bottom: 0;
      }
    }
  }

  .unknownType {
    border: 1px solid $color-error;
    color: $color-error;
    padding: 4px 8px;
  }
}

.priceWrapper {
  width: calc(100% - 20px);

  @include breakpoint(sm) {
    width: 100%;
  }

  .priceTitle {
    color: $color-text-dark;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    width: 100%;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-primary;
    margin-bottom: 27px;

    @include breakpoint(sm) {
      border-bottom: unset;
      font-size: 18px;
    }
  }

  .inputWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;

    input {
      width: 40%;
      border: 1px solid $color-secondary-border;
      padding: 10px 16px;
      outline: none;
    }
  }

  .inputDash {
    height: 1px;
    width: 22px;
    background-color: $color-secondary-border;
  }

  .buttonWrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

    button {
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      width: 49%;

      &:first-child {
        border: 1px solid $color-secondary-border;
      }
    }
  }
}

.innerTrack {
  height: 4px;
  position: absolute;
  background: $color-price;
}

.rangePlaceholder {
  height: 4px;
  background: rgb(217, 217, 217);
  width: 100%;
  max-width: 210px;
  margin-bottom: 25px;
}
