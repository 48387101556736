@import 'src/theme/color';
@import 'src/theme/breakpoints';

.header {
  cursor: pointer;
}

.box {
  display: flex;

  &.customWidth {
    justify-content: space-between;
  }
}

.wrapperComponent {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 32px;

  @include breakpoint(sm) {
    margin-bottom: 20px;
  }
}

.fullWidth {
  .title {
    max-width: unset;
  }
}

.arrow {
  margin-left: 10px;
  font-size: 18px;
  color: $color-primary;
  display: flex;
  align-items: center;
}

.title {
  max-width: 125px;
  width: 100%;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: $color-text-dark;
  text-transform: uppercase;

  span {
    white-space: nowrap;
  }

  &.ordersTitle {
    @include breakpoint(sm) {
      max-width: unset;
      font-weight: 600;
      font-size: 12px;
    }

    > span {
      @include breakpoint(sm) {
        display: block;
        width: 100%;
      }
    }
    + div svg {
      @include breakpoint(sm) {
        fill: $color-text-light;
      }
    }
  }
}

.content {
  font-size: 14px;
  font-weight: 400;
}

.Checkbox {
  padding: 0;
}
