@import 'src/theme/color';

.wrapperComponent {
  background-color: #e9e9e9;
  height: calc(100vw / 2.565);

  img {
    display: none !important;
  }

  &.loaded {
    img {
      display: inherit !important;
    }
  }
}
