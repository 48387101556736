@import 'src/theme/color';
@import 'src/theme/breakpoints';

.mobileList {
  &.isDocuments {
    .mobileListItem {
      @include breakpoint(sm) {
        padding: 0;
        margin: 0;
        border: 0;
      }
      .label,
      .line {
        @include breakpoint(sm) {
          font-size: 12px;
          margin: 0;
          align-self: center;
        }
      }
      .line {
        @include breakpoint(sm) {
          border-bottom: 0;
        }
        &:nth-child(2) {
          @include breakpoint(sm) {
            display: none;
          }
        }
      }
    }
  }
  &.isOrders {
    + div {
      @include breakpoint(sm) {
        padding: 10px 0 0;
      }
    }
    > div {
      @include breakpoint(sm) {
        margin-bottom: 0;
        padding: 5px 0;
        border-bottom: 1px solid #dbdee4;

        > div:nth-child(2) {
          a {
            width: unset;
            justify-content: center;
          }
        }
      }
    }
    .mobileListItem {
      @include breakpoint(sm) {
        padding: 0 24px;
        margin-top: 5px;
        border: 0;

        > div:first-child a {
          margin-left: 0;
        }
      }
      .line {
        @include breakpoint(sm) {
          border: 0;
          padding: 5px 0;
          font-size: 12px;
          max-height: 28px;
        }
        > div:nth-child(2) {
          @include breakpoint(sm) {
            text-align: center;
            white-space: nowrap;
          }
        }
        &:nth-child(2) {
          @include breakpoint(sm) {
            display: none;
          }
        }
        &:last-child {
          @include breakpoint(sm) {
            font-size: 12px;
          }

          > div:last-child {
            @include breakpoint(sm) {
              margin-top: -10px;
            }
          }
        }
      }
    }
  }
  &.isOrder,
  &.isCart {
    .mobileListItem {
      @include breakpoint(sm) {
        position: relative;
        padding: 8px 20px;
        border-bottom: 0;
        margin-bottom: 0;
      }

      &:nth-child(even) {
        @include breakpoint(sm) {
          background: $color-white;
          margin-top: -40px;
        }
      }
      .line {
        @include breakpoint(sm) {
          border-color: $color-primary-background;
          align-items: center;
        }

        &:nth-last-of-type(2) {
          @include breakpoint(sm) {
            border: 0;
          }
        }
      }
      .label {
        @include breakpoint(sm) {
          font-size: 10px;
          text-transform: uppercase;
          margin: 0;
        }

        &,
        + div > div {
          @include breakpoint(sm) {
            line-height: 29px;
          }
        }
        + div > div {
          @include breakpoint(sm) {
            font-size: 12px;
          }
        }
      }
      > div {
        &:first-child {
          @include breakpoint(sm) {
            position: absolute;
            left: -25px;
            top: 50px;
            width: 40px;
            border: 0;
          }
        }
        &:last-child {
          @include breakpoint(sm) {
            position: absolute;
            right: -25px;
            width: 40px;
            top: 61px;
          }

          svg {
            @include breakpoint(sm) {
              margin-bottom: 0;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          @include breakpoint(sm) {
            display: inline-block;
            border: 0;
            padding: 0;
          }
        }
        &:nth-child(2) {
          @include breakpoint(sm) {
            width: 90px;
          }
          > div:first-child {
            @include breakpoint(sm) {
              display: none;
            }
          }
        }
        &:nth-child(3) {
          @include breakpoint(sm) {
            width: calc(100% - 90px);
            padding-left: 6px;
            position: relative;
            top: -75px;
          }
        }
        &:nth-child(4) {
          @include breakpoint(sm) {
            width: calc(100% - 90px);
            margin-left: auto;
            padding-left: 10px;
            flex-direction: column;
            position: relative;
            top: -70px;
            border-bottom: 0;
          }
          .label {
            @include breakpoint(sm) {
              font-weight: 400;
            }
          }
        }
        &:nth-child(n + 5):not(:last-child) {
          @include breakpoint(sm) {
            position: relative;
            top: -70px;
          }
        }
      }
    }
  }

  &.isOrder {
    .mobileListItem {
      @include breakpoint(sm) {
        margin-bottom: 10px;

        img {
          width: 100%;
        }
      }
    }
  }
  .mobileListItem {
    padding: 16px;
    border-bottom: 1px solid $color-gray-border;
    background: $color-white;

    &:nth-child(even) {
      background: $color-gray-background;
    }

    .line {
      border-bottom: 1px solid $color-gray-border;
      padding: 4px 0;
      display: flex;
      align-items: flex-start;

      @include breakpoint(md) {
        justify-content: space-between;
        width: 100%;
      }

      &:last-child {
        border-bottom: none;
      }

      .label {
        font-weight: 600;
      }

      > div {
        margin: 0 4px;
        width: 50%;

        @include breakpoint(md) {
          width: 100%;
        }
      }
    }
  }
}

.table {
  width: 100%;
  color: $color-text-dark;
  font-size: 12px;
  border-collapse: collapse;

  &.isCheckoutTable {
    thead th {
      @include breakpoint(sm) {
        font-size: 10px;
        padding-bottom: 0;
      }
    }
    tbody tr {
      td {
        @include breakpoint(sm) {
          padding: 0;
          font-size: 10px;
        }
      }
    }
  }
}

.th {
  padding: 8px;
  font-weight: 400;
  background: $color-gray-background;

  &.cursor-pointer {
    cursor: pointer !important;
  }

  &.text-align-center {
    text-align: center;
  }
}

.td {
  padding: 16px 8px;
  font-weight: 400;
  background: $color-white;

  &.text-align-left {
    text-align: left;
  }
}

.sortWrapper {
  margin-left: 4px;
  display: inline-flex;
  vertical-align: middle;
}

.sortArrow {
  &.down {
    transform: rotate(180deg);
  }

  &.current {
    color: $color-secondary;
  }
}

.ordersCollapseTitle {
  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 28px;
    text-transform: none;
  }

  > div {
    @include breakpoint(sm) {
      &:first-child {
        margin-left: 0;
      }

      margin-left: 32px;
      flex: 1;
    }
  }

  span {
    @include breakpoint(sm) {
      position: absolute;
      top: -4px;
      left: -4px;
    }

    svg rect {
      @include breakpoint(sm) {
        stroke: $color-orange;
        stroke-width: 2px;
      }
    }
  }
}
