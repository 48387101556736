@import 'src/theme/color';
@import 'src/theme/breakpoints';

.button {
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  color: $color-white;
  background: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  position: relative;

  .label {
    display: inline-flex;
    align-items: center;

    svg {
      margin: 0 4px;
    }
  }

  &.secondary {
    background: $color-secondary;
    border: 1px solid $color-secondary;
  }

  &.danger {
    background: $color-red;
    border: 1px solid $color-red;
  }

  &.large {
    height: 50px;
    font-size: 18px;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }

  &.loading {
    .label {
      visibility: hidden;
    }
  }

  &.square {
    border-radius: 0px;
  }

  &.ghost {
    background: transparent;
    border: 1px solid $color-label;
    color: $color-label;

    @include breakpoint(sm) {
      border-color: $color-primary-background;
    }

    &:hover {
      color: $color-white;
      background: $color-primary;
      border: 1px solid $color-primary;
    }

    &.secondary {
      border: 1px solid $color-secondary;
      color: $color-secondary;
    }

    &.danger {
      border: 1px solid $color-red;
      color: $color-red;
    }
  }

  &.ghostSecondary {
    background: transparent;
    border: 1px solid #a09d9a;
    color: #a09d9a;

    &:hover {
      color: $color-white;
      background: $color-primary;
      border: 1px solid $color-primary;
    }
  }

  .progress {
    color: $color-white;
    position: absolute;
    width: 25px !important;
    height: 25px !important;

    &.ghost {
      color: $color-primary;

      &.secondary {
        color: $color-secondary;
      }

      &.danger {
        color: $color-red;
      }
    }
  }
}
