@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.input {
  width: calc(100% - 88px);
  margin: 0 8px;
  height: 30px;
  border: 0;
  flex: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: $color-text-dark;
  border: 1px solid $color-primary-background;
  @include breakpoint(sm) {
    width: 49px;
    margin: 0;
    font-size: 10px;
    height: 20px;
  }
  &:focus {
    outline: none;
  }
}

.loaderWrapper {
  position: absolute;
  left: 50%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: $color-primary-background;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 14px;
  @include breakpoint(sm) {
    width: 20px;
    height: 20px;
  }
  &:disabled {
    color: $color-text-light;
  }
}
