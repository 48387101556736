@import 'src/theme/color';
@import 'src/theme/utilities';
@import 'src/theme/breakpoints';

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: block;
}

body {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

#root {
  min-height: 100vh;
}

main {
  @include breakpoint(sm) {
    padding-inline: 25px;
    padding-top: 80px;
  }
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.rdp-day_selected,
.rdp-day_range_start {
  border-radius: 100% !important;
}

.thin {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}

.MuiSwitch-root {
  .MuiSwitch-track {
    background-color: $color-primary;
  }
}

.StylePath-Pages-Home-components-Banner .carousel__dot--selected {
  font-weight: 800 !important;
  color: $color-slider-dot !important;
}

.customSlick {
  .slick-slide > div {
    display: flex;
    justify-content: center;
  }
}

.yarl__slide_image {
  -webkit-backface-visibility: unset !important;
  -webkit-transform: unset !important;
}

.yarl__carousel_with_slides {
  column-gap: unset !important;
}

.rdp-button:not(.rdp-nav_button) {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  padding: 10px !important;
}

.rdp-cell {
  width: 25px !important;
  height: 25px !important;
}

.rdp-day_selected {
  background-color: #f4f0eb !important;
  color: $color-text-dark !important;
}

.rdp-day_range_start,
.rdp-day_range_end {
  background-color: #d3cbc3 !important;
}

.rdp-head_cell {
  font-weight: 400 !important;
  color: #a1a4ab !important;
}

.rdp-caption_label {
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize;
}

.SnackbarItem-message {
  white-space: pre-wrap;
}

.scrollableContent {
  display: flex;
  flex-direction: column;
  flex: 1;

  main {
    flex: 1;
  }
}
