@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  min-height: 50vh;

  table {
    th {
      background-color: $color-white;
      font-weight: 600;
      line-height: 140%;
      font-size: 14px;
      color: $color-text-dark;
      @include breakpoint(sm) {
        font-size: 10px;
        padding: 6px 0;
        border-bottom: 1px solid $color-table-border;
      }
    }

    tbody {
      tr:nth-child(2n) {
        background-color: $color-gray-background;
      }
    }

    td {
      font-size: 13px;
      line-height: 140%;
      color: $color-text-dark;
      background-color: transparent;

      @include breakpoint(sm) {
        font-size: 9px;
        padding: 12px 0;
      }

      &:first-of-type {
        min-width: 51px;
      }
    }
  }

  > div:last-child {
    @include breakpoint(sm) {
      padding: 10px 0 0;
    }
  }
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  @include breakpoint(sm) {
    display: block;
    margin: 20px 0 10px;
  }
  .filtersInner {
    display: flex;

    & > div {
      margin-right: 15px;
    }

    > div,
    > button {
      @include breakpoint(sm) {
        width: 33.33%;
        font-size: 10px;
        margin-right: 10px;
        max-height: 38px;
      }

      &:last-child {
        @include breakpoint(sm) {
          margin-right: 0;
        }
      }

      * {
        @include breakpoint(sm) {
          font-size: 10px;
        }
      }

      form {
        @include breakpoint(sm) {
          position: relative;
          max-height: 38px;
        }
        input {
          @include breakpoint(sm) {
            width: 33.33%;
            max-height: 36px;
            padding-top: 0;
          }

          &::placeholder {
            @include breakpoint(sm) {
              font-size: 10px;
            }
          }
        }

        button {
          @include breakpoint(sm) {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            width: 32px;
          }
        }
      }
    }

    > button {
      @include breakpoint(sm) {
        padding: 0 10px;
      }

      svg {
        @include breakpoint(sm) {
          margin-left: 0;
          min-width: 25px;
          margin-right: -5px;
        }
      }
    }
  }

  .clearFilters {
    color: $color-red;
    border: unset;
    background-color: transparent;
    cursor: pointer;
    font-weight: 600;
  }
}

.value {
  white-space: nowrap;

  @media screen and (max-width: 450px) {
    white-space: initial;
  }
}
