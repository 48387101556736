@import 'src/theme/color';

.wrapperComponent {
  .authButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: $color-text;
    margin-left: 12px;
    cursor: pointer;

    svg {
      font-size: 20px;
      margin-left: 12px;
    }
  }
}
