@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/font';

.componentWrapper {
  @include breakpoint(sm) {
    margin-top: 10px;
  }
  .summary {
    background: $color-white;
    padding: 20px 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include breakpoint(md) {
      padding: 0;
      flex-wrap: wrap;
      margin: 0 16px;
    }

    .box {
      padding: 0 15px;
      border-right: 1px solid $color-gray-border;
      width: 25%;
      color: $color-text;

      @include breakpoint(md) {
        padding: 8px;
        width: 50%;
        height: 91px;
        border-right: none;

        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid $color-gray-border;
        }

        &:nth-child(odd) {
          border-right: 1px solid $color-gray-border;
        }
      }

      &:last-of-type {
        border-right: none;
      }

      .value {
        display: block;
        font-size: 24px;
        font-family: $font-poppins;
        font-weight: 800;
        color: $color-text-dark;
        line-height: 2em;

        @include breakpoint(md) {
          text-align: right;
        }

        &.red {
          color: $color-error;
        }
      }
    }
  }

  .listWrapper {
    margin: 15px 0;
    background: $color-white;

    &.invoices {
      margin-top: 0;
    }

    .listHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(89.98deg, #757575 0.02%, #939393 99.99%);
      border-radius: 4px 4px 0 0;
      height: 42px;
      padding: 0 16px;
      color: $color-white;
      font-size: 16px;

      &.blue {
        background: linear-gradient(89.98deg, #1436ad 0.02%, #6d96ff 99.99%);
      }

      &.orange {
        background: linear-gradient(89.98deg, #f49120 0.02%, #ffab6d 99.99%);
      }

      &.violet {
        background: linear-gradient(89.98deg, #ae5fed 0.02%, #a78bf5 99.99%);
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        text-decoration: none;
        font-size: 14px;
        line-height: 2em;

        svg {
          margin-left: 10px;
        }
      }
    }

    .listTabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      @include breakpoint(md) {
        border-bottom: 1px solid $color-gray-border;
      }

      .tab {
        background: $color-gray-border;
        width: calc(100% / 3 - 10px);
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text;
        border-bottom: 3px solid $color-gray-border;
        cursor: pointer;

        &.active {
          background: $color-white;
          border-color: $color-orange;
          color: $color-text-dark;
          font-weight: 600;
        }
      }
    }

    .tableWrapper {
      th {
        padding: 8px 16px;
      }

      td {
        font-size: 14px;
        padding: 8px 16px;
      }

      .primaryText {
        color: $color-primary;
      }

      .grayText {
        color: $color-text;
      }

      .redText {
        color: $color-error;
      }
    }

    .mobileListItem {
      .itemHeader {
        color: $color-primary;
        border-bottom: 1px solid $color-gray-border;
        height: 36px;
        padding: 0 16px;
        display: flex;
        align-items: center;
      }

      .details {
        border-bottom: 1px solid $color-gray-border;
        display: flex;
        align-items: center;
        padding: 8px 0;

        > div {
          width: 100%;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-right: 1px solid $color-gray-border;

          &:last-child {
            border-right: none;
          }

          .label {
            color: $color-text-light;
          }
        }
      }
    }

    .listFooter {
      padding: 16px;

      .summary {
        margin: 0;

        .title {
          margin-top: 16px;
          font-size: 16px;
          color: $color-secondary;
          font-weight: 500;
        }

        .items {
          padding: 15px 0;
          display: flex;
          align-items: center;

          > div {
            margin-right: 16px;
            color: $color-text-dark;
            font-weight: 700;

            span {
              color: $color-text;
              font-weight: 400;
            }
          }
        }
      }

      .total {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-top: 1px solid $color-gray-border;
        padding-top: 12px;

        .title {
          color: $color-text;
          font-weight: 700;
        }

        .prices {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          color: $color-text;

          .gross {
            font-size: 16px;
            color: $color-secondary;
          }
        }
      }
    }
  }

  .invoice {
    margin: 0 16px;
    border-bottom: 1px dashed $color-gray-border;

    &:last-of-type {
      border-bottom: none;
    }

    .header {
      cursor: pointer;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-text;

      svg {
        width: 14px;
        height: 14px;
      }

      &.expanded {
        color: $color-primary;

        svg {
          transform: rotate(180deg);
        }
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 12px;

      .box {
        text-align: center;
        padding: 0 4px;
        color: $color-text;

        .label {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .bottomActions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    @include breakpoint(sm) {
      flex-wrap: wrap;
      column-gap: 6px;
      row-gap: 12px;
      margin-bottom: 0;
      margin-top: 15px;
    }
    .action {
      border: 1px solid #e0dbd7;
      border-radius: 4px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 190px;
      justify-content: space-between;
      text-decoration: none;
      @include breakpoint(sm) {
        width: calc(50% - 6px);
        padding: 27px 20px 13px;
      }
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 37px;
      margin-top: 15px;
      color: $color-text-dark;
      @include breakpoint(sm) {
        margin-top: 0;
      }
    }
  }
}

.payments,
.orders {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  @include breakpoint(sm) {
    display: block;
  }
  .tile {
    width: calc(50% - 7px);
    border: 1px solid #dbb57c;
    border-radius: 4px;
    padding: 15px 30px 30px;
    @include breakpoint(sm) {
      width: 100%;
      padding: 15px 30px;
      margin-bottom: 15px;
    }
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #dbb57c;
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      @include breakpoint(sm) {
        font-size: 12px;
        margin-bottom: 16px;
      }

      svg {
        margin-right: 8px;
      }
    }

    .brutto {
      font-weight: 400;
      @include breakpoint(sm) {
        font-size: 10px;
      }
    }

    &:nth-child(2) {
      border: 1px solid $color-red;

      .title {
        color: $color-red;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      padding: 4px 0;
      border-bottom: 1px solid rgba($color: $color-text-search, $alpha: 0.1);
      @include breakpoint(sm) {
        padding: 0;
        font-size: 10px;
      }
      &:nth-child(2) {
        color: $color-text;
      }

      .price {
        color: #dbb57c;
        @include breakpoint(sm) {
          font-size: 10px;
        }
      }

      &:nth-child(3) {
        border-bottom: unset;
        font-size: 16px;
        font-weight: 700;

        .price {
          color: $color-red;
        }

        > div:first-child {
          margin-left: 50px;
          @include breakpoint(sm) {
            margin-left: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.orders {
  .tile {
    border: 1px solid #a69797 !important;

    &:nth-child(2) {
      .title {
        justify-content: space-between;
        @include breakpoint(sm) {
          display: block;
          margin-bottom: 0;
        }
        > div {
          * {
            @include breakpoint(sm) {
              font-size: 10px;
              padding: 0;
              margin: 0;
            }
          }
          > div > div {
            &,
            > div {
              @include breakpoint(sm) {
                flex: unset;
                justify-content: flex-start;
              }
            }
          }

          svg {
            @include breakpoint(sm) {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .title {
      color: #a69797 !important;
    }

    .row {
      > div {
        margin-left: 0 !important;
        @include breakpoint(sm) {
          font-size: 10px;
        }
      }
      &:nth-child(3) {
        > div:first-child {
          @include breakpoint(sm) {
            font-size: 10px;
          }
        }
      }
      .orderSummary {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        @include breakpoint(sm) {
          flex-direction: column;
          align-items: flex-start;
        }
        > span {
          @include breakpoint(sm) {
            color: #a69797;
          }
        }
        span {
          &,
          > span {
            @include breakpoint(sm) {
              font-size: 10px;
            }
          }
          &:not(:first-child) {
            @include breakpoint(sm) {
              margin-top: -10px;
            }
          }
          &:first-child {
            @include breakpoint(sm) {
              margin-top: 6px;
            }
          }
        }
        span .totalCount {
          color: #df992f;
          font-weight: 700;
        }

        .paidCount {
          color: #63aa77;
          font-weight: 700;
        }

        .expiredCount {
          color: $color-red;
          font-weight: 700;
        }
      }

      .usedLimit {
        color: #a69797;
        @include breakpoint(sm) {
          font-size: 10px;
        }
      }
    }
  }
}
