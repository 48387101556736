@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.productDetails {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 50px;

  @include breakpoint(md) {
    padding: 0;
  }

  .mainContent {
    padding-right: 35px;

    @include breakpoint(sm) {
      padding: 0;
    }
  }
}

//h1
.title {
  display: block;
  width: 100%;
  margin: 0 0 25px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: $color-text-dark;
}

.labels {
  margin-bottom: 40px;
  &,
  + div {
    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }
}

.producer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
  color: $color-gray-border;

  a {
    color: inherit;
    text-decoration: none;
  }
}

//ul
.list {
  display: flex;
  flex-flow: wrap row;
  list-style: none;
  margin: 0;
  padding: 0;
}

//li
.listItem {
  position: relative;
  display: block;
  width: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-text;

  &:not(:last-child) {
    padding-right: 15px;
    margin-right: 14px;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      right: 0;
      width: 1px;
      height: 10px;
      background-color: $color-text;
    }
  }

  strong {
    display: inline;
    font-weight: 500;

    a {
      text-decoration: none;
      color: $color-primary;
    }
  }
}

.description {
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin-bottom: 24px;

  @include breakpoint(sm) {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

//span
.label {
  display: block;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: $color-text;
}

.text {
  display: block;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: $color-text;

  br {
    display: none;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;

    li {
      font-size: 14px;
      line-height: 140%;

      &::before {
        content: '·';
        margin: 0 5px;
        font-weight: 500;
      }

      &::marker {
        display: none;
      }

      div {
        display: inline;
      }
    }
  }
}

.priceWrapper {
  margin-bottom: 14px;
  @include breakpoint(sm) {
    margin-bottom: 25px;
  }
  span {
    display: block;
    font-style: normal;
    line-height: 140%;
    color: $color-price;
  }

  .nettoPrice {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;
  }

  .oldPrice {
    text-decoration: line-through;
    font-weight: 400;
    font-size: 14px;
  }

  .discountPrice {
    color: $color-red;
  }

  .eurPrice {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 12px;
    @include breakpoint(sm) {
      margin-bottom: 5px;
    }
  }

  .retailPrice {
    font-weight: 400;
    font-size: 12px;

    span {
      display: inline;
    }

    .retailOldPrice {
      text-decoration: line-through;
      margin-right: 4px;
    }

    .retailPromoPrice {
      color: $color-red;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.loginButton {
  text-decoration: none;
  color: $color-primary;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;

  small {
    font-weight: 400;
  }
}

.block {
  display: flex;
  flex-flow: wrap row;
  width: 100%;
}

.promotion {
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: $color-violet;
  font-size: 14px;
  line-height: 20px;

  svg {
    font-size: 20px;
    margin-right: 4px;
  }

  strong {
    font-weight: 700;
    margin-left: 4px;
  }

  a {
    font-size: 12px;
    color: $color-black;
    text-decoration: none;
    margin-left: 8px;
  }
}
.collapseTitle {
  @include breakpoint(sm) {
    font-size: 16px;
  }
}
.download {
  border: unset;
  background-color: transparent;
  padding: 0;

  &:disabled {
    opacity: 0.5;
  }

  > div {
    height: unset;
    margin-left: 20px;
  }
}

.sizingTable,
.download {
  cursor: pointer;
  margin-top: 24px;
  display: flex;
  align-items: center;
  text-decoration: none;

  @include breakpoint(sm) {
    margin-top: 0;
  }

  @media screen and (max-width: 500px) {
    margin: 10px auto 0;
  }

  span {
    margin-left: 19px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $color-text-dark;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: 14px;
      margin-left: 15px;
    }
  }
  svg {
    @include breakpoint(sm) {
      width: 23px;
    }
  }
}

.sizingTableTitle {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}
