@import 'src/theme/color';
@import 'src/theme/breakpoints';

$background-image: '/assets/footer-background.png';

.componentWrapper {
  background: $color-primary-background;
  color: $color-text-dark;

  .contentWrapper {
    padding: 60px 0;
    display: flex;
    align-items: flex-start;

    @include breakpoint(sm) {
      padding: 35px 10px;
      flex-direction: column;
    }

    > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      @include breakpoint(sm) {
        flex-direction: column;
        width: 100%;
      }
    }

    .logoWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 4;

      img {
        max-width: 278px;
        margin-bottom: 20px;
      }

      svg {
        margin-bottom: 32px;
      }

      section {
        margin-right: 85px;
        li {
          font-size: 12px;
        }
      }

      @include breakpoint(md) {
        img {
          margin-bottom: 0;
        }
      }

      @include breakpoint(sm) {
        order: 2;
        justify-content: center;
        margin-top: 13px;

        img {
          max-width: 200px;
        }

        section {
          margin-right: 0;
          li {
            font-size: 14px;
          }
        }
      }
    }

    .footerSections {
      flex: 5;

      @media screen and (min-width: 1600px) {
        section {
          max-width: 155px;
          width: 100%;
        }
      }
    }

    a {
      text-decoration: none;
      color: $color-text-dark;
    }

    section {
      margin-right: 65px;

      @include breakpoint(sm) {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
        padding: 26px 0 0;
      }

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        padding-bottom: 10px;
        text-transform: uppercase;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $color-gray-border;

        @include breakpoint(sm) {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          display: inline;
        }

        svg {
          display: none;
          font-size: 22px;
        }

        @include breakpoint(sm) {
          svg {
            display: inline-block;
          }
        }

        &.open {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      ul {
        margin: 10px 0 0;
        padding: 0;
        list-style: none;

        li {
          margin-bottom: 14px;
          font-size: 13px;
          line-height: 140%;

          @include breakpoint(sm) {
            text-align: center;
            font-size: 14px;
          }

          p {
            margin: 0;
            line-height: 170%;
          }
        }
      }
    }

    @include breakpoint(sm) {
      .hidden {
        display: none;
      }
    }
  }

  .newsletter {
    width: 100%;
    margin-top: 20px;
    margin-right: 20%;
    @include breakpoint(sm) {
      order: 3;
    }

    @media screen and (min-width: 1600px) {
      margin-right: 8%;
    }

    > div {
      max-width: 340px;
      margin-left: auto;
      margin-right: 40px;

      @include breakpoint(md) {
        margin-right: 0;
      }

      @include breakpoint(sm) {
        max-width: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }

    .title {
      display: block;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      text-transform: uppercase;
      margin-bottom: 10px;
      text-align: center;

      @include breakpoint(sm) {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }

    input {
      height: 100%;
      width: 100%;
      max-width: 230px;
      outline: none;
      border: 1px solid #bbb3b3;
      margin-right: 10px;
      padding: 13px;
      flex: 1;
      color: $color-text-light;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      z-index: 5;
      border-radius: 4px;
      background-color: #f4f3f3;

      @include breakpoint(sm) {
        width: 230px;
        margin-right: 0;
        margin-bottom: 5px;
        border-radius: 4px;
        text-align: center;
      }

      &::placeholder {
        color: $color-text-light;
      }
    }

    button {
      font-weight: 700;
      font-size: 13px;
      line-height: 140%;
      padding: 0 15px;
      background-color: $color-orange;

      @include breakpoint(sm) {
        width: 230px;
        border-radius: 4px;
      }
    }

    .newsletterFormWrapper {
      display: flex;

      @include breakpoint(sm) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
