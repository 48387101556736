@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  aspect-ratio: 0.807;

  @include breakpoint(sm) {
    display: block;
    aspect-ratio: 0.508;
    margin-bottom: 34px;
  }

  @include breakpoint(md) {
    justify-content: center;

    div {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  .main-image {
    width: 100%;
    aspect-ratio: 0.658;
    margin-right: 16px;
    cursor: pointer;
    display: block;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .thumb {
    cursor: pointer;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;

    img {
      display: block;
      aspect-ratio: 0.658;

      &.videoThumbnail {
        opacity: 0.5;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include breakpoint(sm) {
      background-position: center;
    }
  }
}

.slickWrapper {
  max-width: 120px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 768px) and (max-width: 900px) {
    max-width: 380px !important;
  }

  @include breakpoint(sm) {
    max-width: unset;
    display: block;
    margin: 20px auto 0 auto !important;
  }
  button {
    z-index: 999;

    &::before {
      display: none;
    }

    &:first-of-type {
      top: -36px;
      left: 50%;
      transform: translate(-50%, 0) !important;

      @include breakpoint(sm) {
        top: 50%;
        left: 30px;
        transform: translate(-50%, 0) rotate(270deg) !important;
      }

      + div {
        @include breakpoint(sm) {
          max-width: 200px;
          margin: 0 auto;
        }

        > div {
          @include breakpoint(sm) {
            display: flex;
            column-gap: 17px;
          }
        }
      }
    }

    &:last-of-type {
      top: calc(100% + 15px);
      left: 50%;
      transform: translate(-50%, 0) !important;

      @include breakpoint(sm) {
        top: 50%;
        left: unset;
        right: 20px;
        transform: translate(-50%, 0) rotate(270deg) !important;
      }
    }

    svg {
      color: $color-primary;
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  img {
    margin: 0 auto;
  }
}

.imagesWrapper {
  margin-left: 17px;

  @include breakpoint(sm) {
    display: flex;
    justify-content: center;

    > div {
      margin: 0 10px;
    }
  }

  > div {
    margin-bottom: 5px;
  }
}

.label {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 6px 10px;
  background: $color-primary;
  font-size: 12px;
  font-weight: 700;
  color: $color-white;

  &.new {
    background: $color-accent;
  }

  &.bestseller {
    background: $color-bestseller;
  }

  &.promotion {
    background: $color-violet;
  }
}

.labels {
  position: absolute;
}
