@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  margin-bottom: 40px;

  @include breakpoint(md) {
    max-width: none;
    margin-bottom: 0px;
  }
}

.changePasswordTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  padding-bottom: 15px;
  border-bottom: 1px solid $color-primary;
  margin-bottom: 16px;

  span {
    font-size: 12px;
    line-height: 12px;
  }

  @include breakpoint(md) {
    font-size: 14px;
    padding-bottom: 7px;
  }
}

.clientApiWrapper {
  margin-top: 30px;

  .accountWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .tokenWrapper {
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: $color-text-dark;
    }

    @include breakpoint(sm) {
      padding: 0;
      display: block;
    }

    > div:first-child {
      width: 100%;
      position: relative;

      @include breakpoint(sm) {
        max-width: 100%;
      }

      svg {
        position: absolute;
        right: 4px;
        bottom: 3px;
        cursor: pointer;
      }
    }

    input {
      font-size: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 40px;
    }

    button {
      margin-left: 20px;
      font-weight: 700;
      font-size: 12px;

      @include breakpoint(sm) {
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
