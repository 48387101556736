@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  width: 100%;
  padding: 16px 0;
  margin-bottom: 8px;
  background-color: $color-white;
  max-width: 300px;
  margin: 0 auto;
  position: relative;

  @include breakpoint(sm) {
    padding: 0 2px;
    margin-bottom: 18px;
  }

  &Line {
    @include breakpoint(sm) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-gray-border;
      max-width: unset;

      .topBar {
        position: unset !important;
        .labels {
          > span {
            padding: 3px 0;
            display: block;
            width: 80px;
            text-align: center;
            margin-right: 0;
            font-size: 9px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .topBar {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .labels {
      display: flex;
      gap: 4px;
    }
  }

  .productInfo {
    @include breakpoint(sm) {
      margin-top: 10px;
      padding: 0 10px;
    }

    .title {
      font-size: 13px;
      line-height: 120%;
      color: $color-text-dark;
      margin-bottom: 8px;

      @include breakpoint(sm) {
        font-size: 12px;
        line-height: 140%;
        margin: 6px 0;
      }
    }

    .producer {
      color: $color-text-light;
      font-size: 12px;

      span {
        padding-right: 3px;

        &:nth-child(2) {
          font-weight: 500;
        }

        &:last-child {
          padding-right: 0;
          color: $color-text;
          font-weight: 500;

          @include breakpoint(sm) {
            text-transform: uppercase;
            font-weight: 500;
            line-height: 140%;
            color: $color-text-light;
          }
        }
      }
    }
  }

  .productContent {
    padding: 16px 0;
    width: 100%;

    @include breakpoint(sm) {
      padding: 0;
    }

    img {
      max-width: 100%;
      max-height: 444px;
      aspect-ratio: 0.658;
      margin: 0 auto;
      display: block;
    }

    &:not(.productContentLine) {
      img {
        width: 100%;
      }
    }

    &.productContentLine {
      a {
        text-decoration: none;
      }

      img {
        max-width: 80px;
      }

      .additionalInfo {
        .stockWrapper {
          margin-top: 0;

          > span {
            display: block;
          }
        }

        .variantsWrapper {
          margin-top: 15px;
        }
      }

      > div {
        width: 100%;

        > div {
          flex: 1;
        }
      }
    }

    .priceWrapper {
      text-align: right;
      color: $color-label;
      line-height: 140%;

      @include breakpoint(sm) {
        display: flex;
        flex-flow: wrap row;
        width: 100%;
        padding: 0 10px;
        text-align: left;
      }

      .netto {
        span {
          &:first-child {
            line-height: 120%;
            font-size: 12px;
            padding-right: 7px;
            text-decoration-line: line-through;
          }

          &:nth-child(2) {
            font-size: 18px;
            font-weight: 700;
            color: $color-text-dark;
            padding-right: 4px;
          }

          &:last-child {
            font-weight: 300;
            color: $color-text-light;
          }
        }
      }

      .old {
        display: flex;
        flex-wrap: nowrap;
        margin-right: 6px;
        font-weight: 400;
        text-decoration-line: line-through;
        color: $color-price;

        + .net {
          color: $color-red;
        }
      }

      .net {
        display: inline-flex;
        flex-flow: wrap row;
        align-items: flex-end;
        color: $color-price;
        font-weight: 700;
      }

      .brutto {
        span {
          &:last-child {
            font-weight: 300;
            color: $color-text-light;
            padding-left: 3px;
          }
        }
      }
    }

    .options {
      display: flex;
      align-items: center;
      gap: 14px;
      flex-wrap: wrap;
      justify-content: flex-end;

      @include breakpoint(sm) {
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 8px;
        padding: 0 10px;
      }

      .additionalInfo {
        @include breakpoint(sm) {
          display: flex;
          flex-direction: column;
          row-gap: 7px;
        }

        .variantsWrapper,
        .stockWrapper > div > div {
          @include breakpoint(sm) {
            font-size: 12px;
            font-weight: 500;
            line-height: 140%;
          }
        }

        .addToList {
          display: flex;
          align-items: center;

          svg {
            margin-right: 12px;
            width: 18px;
            height: 20px;
            color: $color-primary;
          }
        }
      }
    }

    .loginButton {
      @include breakpoint(sm) {
        color: $color-orange;
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
        text-decoration: none;
        margin: 10px 10px 0;

        small {
          font-weight: 400;
          font-size: 10px;
        }
      }
    }

    .productOrder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;

      > *:not(.loginButton) {
        width: max-content;
        height: 44px;
        display: flex;
        align-items: center;
      }
    }

    &Line {
      &,
      > div {
        @include breakpoint(sm) {
          display: flex;
        }
      }

      .productInfo,
      .title {
        @include breakpoint(sm) {
          margin-top: 0;
          max-width: 125px;
        }
      }

      .producer {
        @include breakpoint(sm) {
          margin-bottom: 10px;
        }
      }

      .priceWrapper {
        @include breakpoint(sm) {
          margin-top: 10px;
        }
      }

      .options {
        @include breakpoint(sm) {
          padding: 0;
          margin: 0;
        }

        .additionalInfo {
          @include breakpoint(sm) {
            row-gap: 0;
          }

          > span + span {
            @include breakpoint(sm) {
              margin-top: 15px;
            }
          }
        }

        .variantsWrapper,
        .stockWrapper {
          > span {
            @include breakpoint(sm) {
              color: $color-price;
              font-size: 10px;
              font-weight: 500;
              line-height: 140%;
            }
          }

          > div {
            @include breakpoint(sm) {
              margin-top: 5px;
            }
          }
        }

        .stockWrapper {
          @include breakpoint(sm) {
            order: -1;
          }
        }
      }
    }
  }

  .dropdownAction {
    display: flex;
    align-items: center;
    gap: 11px;
    font-size: 13px;
    list-style: 120%;
    color: $color-text-dark;

    &.cart {
      margin-top: 12px;

      :first-child {
        color: $color-primary !important;
      }
    }

    &.bin {
      color: $color-bin !important;
      margin-bottom: 12px;
    }
  }
}
