@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;

  @include breakpoint(sm) {
    span {
      font-size: 18px;
    }

    > div {
      margin-bottom: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-primary-background;
    }
  }
}

.content {
  margin-top: 24px;
}

.info {
  display: flex;
  align-items: flex-start;
  color: $color-info;
  padding: 8px;
  background: $color-gray-background;
  border: 1px solid $color-secondary-background;
  box-shadow: 0 1px 2px rgba(6, 25, 56, 0.05);
  border-radius: 2px;

  svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.category {
  position: relative;
  padding-left: 20px;

  &.main {
    > button {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: $color-text-dark;

      span > span {
        padding: 3px;
      }
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;

    &:hover {
      color: $color-primary;
    }

    &.chosen {
      color: $color-primary;
      font-weight: 700;
    }

    .counter {
      font-size: 12px;
      font-style: normal;
    }
  }
}
