@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 650px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  background-color: $color-white;
  padding: 23px 16px 28px 16px;
  outline: none;
  border-radius: 4px;

  &.disableMaxHeight {
    max-height: unset;
  }

  &.isOverLogin {
    top: 51px;
    right: calc(((100vw - 1230px) / 2) + 20px);
    transform: translate(0);

    @media screen and (max-width: 1230px) {
      left: 50%;
      transform: translate(-50%, 0);
    }

    @include breakpoint(sm) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px !important;
    }
  }

  @include breakpoint(md) {
    min-width: auto;
  }

  &.consent {
    min-width: 300px;
    max-width: 508px;

    .title {
      font-weight: 400;
      font-size: 14px;
    }
  }

  &.fullScreen {
    width: 100%;
    height: 100%;
    min-width: auto;
    max-width: calc(100vw - 8px);
    max-height: calc(100vh - 8px);

    @include breakpoint(sm) {
      padding: 32px;
    }
  }

  &.fullWidth {
    width: 100%;
    height: 100%;
    min-width: auto;
    max-width: calc(90vw);
    max-height: calc(80vh);
  }

  :global {
    .confirmationModalContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .confirmationModalActions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: 1px solid $color-gray-background-dark;
      padding-top: 20px;

      button {
        min-width: 160px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;

        &:hover {
          color: $color-white;
        }

        &:first-child {
          border: 1px solid $color-secondary-border;
        }
      }

      button + button {
        margin-left: 16px;
        color: $color-white;
        font-weight: 600;
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 40px;
}

.close {
  color: $color-text;
  cursor: pointer;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.title {
  padding-left: 30px;
  color: $color-text-dark;
  line-height: 140%;
  font-weight: 600;
  font-size: 16px;
  flex: 1;
  text-align: center;
}

.overloginWrapper {
  background-color: $color-gray-background;
  margin: -10px;
  padding: 20px 40px;
  margin-bottom: 10px;

  button {
    font-size: 16px;
    font-weight: 500;
    color: $color-secondary;
    display: flex;
    align-items: center;
    border: unset;
    background-color: transparent;

    svg {
      margin-right: 20px;
    }
  }
}

.isFiltersOverlay {
  &.fullScreen {
    padding: 0;
  }

  .title {
    width: 100%;
  }

  .header {
    margin-bottom: 0;
  }
}

.blur {
  backdrop-filter: blur(25px);
}
