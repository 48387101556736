@import 'src/theme/breakpoints';
@import 'src/theme/color';

.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 50px;

  @include breakpoint(md) {
    padding: 0 16px;
  }
}

.vertical {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 0 10px;

  @include breakpoint(sm) {
    display: block;
    padding: 0;
  }

  .formElement {
    margin-bottom: 3px;

    &:first-child {
      input {
        font-size: 30px;

        &::placeholder {
          font-size: 40px;
        }
      }
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: $color-text-dark;
    }

    input {
      font-size: 12px;
      line-height: 20px;
      max-width: 280px;
      color: $color-gray-checkbox;

      &::placeholder {
        font-size: 12px;
      }

      @include breakpoint(sm) {
        max-width: unset;
      }
    }
  }

  .actions {
    margin-top: auto;

    @include breakpoint(sm) {
      margin-top: 10px;
    }

    button {
      height: 36px;
      width: 100%;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 140%;
    }
  }
}

.formElement {
  width: 100%;
  margin-bottom: 12px;

  label {
    font-weight: 700;
    display: block;
    padding-bottom: 2px;
  }
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}
