$color-error: #c71717;
$color-success: #44d038;

$color-body-bg: #dedede; //gray10

$color-table: #dedede; //gray10

$color-primary-background: #ede8e4;
$color-gray-background-dark: #e7e9ee;
$color-gray-background: #f9f9f9; //gray50
$color-gray-border: #d9d9d9; //gray300 borderGray

$color-product-bg: #f4f4f4; //backgroundGray

$color-black: #000000;
$color-main-black: #20262e;
$color-orange: #df992f;
$color-blue-promotion: #39b0d9;
$color-woodsmoke: #15181e;

$color-overlay: rgba(158, 158, 158, 0.5);

$color-primary: #e1a346;

$color-accent: #cae041;
$color-violet: #cd006c;

$color-secondary: #7e7676;
$color-secondary-background: #eee6fa; //secondary50
$color-secondary-border: #d9d9d9; //secondary200
$color-menu-background: #f0efef;
$color-highlighted-position: #bebebe;

$color-collapsed-border: #d9d9d9; //collapseBorder

$color-text: #55565a; //gray800
$color-text-dark: #383a3c; //gray900
$color-text-light: #8d9096; //gray600
$color-label: #717378; //gray700
$color-price: #676a6d;
$color-breadcrumb: #6a6b6d;

$color-white: #ffffff;
$color-black: #000000;
$color-red: #cd4e5d;
$color-affirmative: #63aa77;

$color-gray: #f9f9f9;
$color-gray-checkbox: #d9d9d9; //gray500
$color-gray-radio: #55565a;

$color-stock-low: #d3c1f2;
$color-stock-middle: #b598ea; //secondary200
$color-stock-big: #6525d5;

// alerty

$color-alert-error-bg: #f8e8e8;
$color-alert-error-border: #db8c8c;
$color-alert-success-bg: #e7f4e7;
$color-alert-success-border: #87c987;
$color-alert-warning-bg: #fff4e5;
$color-alert-warning-border: #ffb142;

$color-counter: #f4f4f4;

$color-dropdown: #d9d9d9; //secondary300 secondary400

$color-file-bg: #e7e9ee; //gray100
$color-file-text: #976ce3; //secondary300 secondary400
$color-file-border: #717378;

$color-summary-border: #dbdee4; //gray200
$color-faq-active: #91a9ff;
$color-slider-tray: #91a9ff;

$color-selected-bg: #d3c1f2;

$color-news: #1a45df;
$color-title-counter: #5e82ff;
$color-slider-dot: #1d4efa;
$color-block: #c4d1ff;
$color-link: #976ce3; //secondary300-400

$color-bestseller: #b598ea; //secondary200
$color-product-hover: #d3c1f2; //secondary100

$color-text-search: #333333;
$color-search-border: #d3c1f2; //secondary100

$color-loader: #976ce3; //secondary300 secondary400
$color-info: #976ce3; //secondary300 secondary400

$color-form-border: #dbdee4; //gray200

$color-status: #a1a4ab; //gray500

$color-order-bg: #f4f4f4;

$color-tab-bg: #f3f4f7;
$color-tab-border: #e7e9ee; //gray100

$color-table-border: #dbdee4; //gray200

$color-shopping-button: #1436ad;

$color-search: #d8dce4;
$color-product-id: #1436ad;

$color-price-border: #d8dce4;

$color-bin: #b81919;

$color-sort-active: #6525d5;

$color-gray-grid: #e2e3e4;
$color-gray-grid-active: #bdb4b4;
