@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  position: relative;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1800px) {
    max-width: 1415px;
  }

  @media screen and (max-width: 1600px) {
    max-width: 1130px;
  }

  @media screen and (min-width: 1201px) and (max-width: 1399px) {
    max-width: 845px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 845px;
  }

  @include breakpoint(md) {
    max-width: 100vw;
    width: 100%;
  }

  > div > div {
    padding-bottom: 30px;
  }

  .navButton {
    height: 44px;
    width: 44px;
    border: none;
    border-radius: 100%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $color-product-bg;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 99;

    &:disabled {
      display: none;
    }

    &.back {
      left: -22px;
    }

    &.next {
      right: -22px;
    }

    svg {
      font-size: 24px;
    }
  }

  .trayBar {
    display: flex;
    margin-top: 27px;
    border: none;

    button {
      border-left: none;
      border-right: none;
      width: 100%;
      height: 4px;
      border-top: none;
      border-bottom: none;

      &:first-child {
        border-radius: 100px 0 0 100px;
      }

      &:last-child {
        border-radius: 0 100px 100px 0;
      }

      &:disabled {
        background-color: $color-faq-active;
        border-radius: 100px;
      }
    }
  }

  button {
    top: 35%;
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: $color-counter;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    &:first-of-type {
      @media screen and (min-width: 768px) and (max-width: 1000px) {
        left: -15px;
      }
    }

    &:last-of-type {
      @media screen and (min-width: 768px) and (max-width: 1000px) {
        right: -15px;
      }
    }

    @include breakpoint(sm) {
      opacity: 0.8;
      left: 0;

      &:nth-of-type(2) {
        left: unset;
        right: 0;
      }
    }

    &:hover {
      background-color: darken($color: $color-counter, $amount: 10%);
    }

    &::before {
      display: none;
    }

    svg {
      width: 25px;
      height: 25px;
      color: #a09393;
      margin-right: 0;
    }
  }

  .slide {
    max-width: 275px;

    > div {
      > div:first-child {
        @include breakpoint(sm) {
          justify-content: center;
        }

        img {
          width: 275px !important;
        }
      }
      > div:nth-child(2) {
        width: 275px !important;
      }
    }
  }

  @media screen and (min-width: 1801px) {
    .slide {
      max-width: 262px !important;

      > div {
        > div:first-child {
          img {
            width: 262px !important;
          }
        }
        > div:nth-child(2) {
          width: 262px !important;
        }
      }
    }
  }
}
