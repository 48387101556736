@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.componentWrapper {
  width: 100%;
  padding-bottom: 20px;

  @media screen and (min-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  button {
    border: none;
    cursor: pointer;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 40px;
    color: $color-white;
    background: #bc8c66;
    font-size: 13px;
    font-weight: 600;
  }
}

.contentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;

  @include breakpoint(md) {
    background: $color-white;
    height: auto;
    padding: 16px;
    box-shadow: 0 2px 5px rgba(6, 25, 56, 0.06);
    position: sticky;
    top: 0;
    z-index: 2;
    margin-bottom: 16px;
  }
}

.link {
  color: $color-primary;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}

.backIcon {
  margin-right: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.heading {
  border-bottom: 1px solid $color-primary;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    margin: 0 0 11px;
    text-transform: uppercase;
  }
}

.content {
  padding-left: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 50vh;

  @include breakpoint(md) {
    padding: 16px;
    flex-direction: column-reverse;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    color: $color-text-dark;
    margin-bottom: 20px;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    color: $color-text-dark;
    margin-bottom: 20px;

    span {
      color: $color-primary;
    }
  }

  .orderNo {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color-primary;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: $color-label;
    margin-bottom: 40px;
  }
}

.details {
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 5px;

    & + span {
      font-size: 14px;
    }
  }

  span {
    display: block;
    line-height: 140%;
    margin-bottom: 2px;
    font-size: 16px;
  }
}

.image {
  display: block;
  margin-left: 50px;

  @include breakpoint(md) {
    margin-left: 0;
    width: 200px;
  }
}

.tilesWrapper {
  margin-bottom: 50px;

  @include breakpoint(md) {
    padding: 0 16px;
  }

  .title {
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 32px;
  }

  .tiles {
    margin: 28px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(md) {
      flex-direction: column;
    }

    .tile {
      width: calc(33% - 2px);
      height: 250px;
      background-size: cover;

      @include breakpoint(md) {
        width: 100%;
        margin-bottom: 8px;
      }

      .label {
        font-size: 24px;
        padding: 30px 35px;
        color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff90;
        cursor: pointer;
      }
    }
  }

  .readMore {
    text-align: right;

    a {
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      color: $color-text-dark;

      svg {
        margin-left: 8px;
      }
    }
  }
}
