@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/utilities';

.componentWrapper {
  display: flex;
  align-items: center;
  color: $color-text;
  flex: 1;

  .container {
    width: 100%;
    padding: 0 15px;

    @media screen and (min-width: 1230px) {
      margin: 0 auto;
    }

    @media screen and (max-width: 1200px) {
      padding: 0;
    }
  }

  @include breakpoint(sm) {
    padding: 16px 24px !important;
    justify-content: space-between;
    position: fixed;
    width: 100vw;
    background: $color-white;
    height: $header-height-mobile;
    z-index: 998;
  }

  @include breakpoint(md) {
    padding: 8px;
  }

  @include breakpoint(lg) {
    padding: 15px;
  }

  &.simpleHeader {
    -webkit-box-shadow: 0px 2px 27px 3px rgba(231, 226, 226, 1);
    -moz-box-shadow: 0px 2px 27px 3px rgba(231, 226, 226, 1);
    box-shadow: 0px 2px 27px 3px rgba(231, 226, 226, 1);
  }

  .customerInfo {
    display: flex;
    flex: 1;
    color: $color-text-light;
    font-weight: 500;
    font-size: 14px;
  }

  .user {
    display: flex;
    align-items: center;
    margin-right: 20px;
    white-space: nowrap;

    a {
      color: unset;
      text-decoration: none;
    }

    svg {
      margin-right: 7px;
    }

    &:nth-child(2) {
      padding-left: 20px;
      border-left: 1px solid $color-gray-border;
    }
  }

  .logo {
    flex: 1;
    display: flex;
    justify-content: center;
    min-width: 100px;
    max-width: 200px;
    width: 200px;
    margin: 0 5px;

    @include breakpoint(lg) {
      flex: unset;
    }

    img {
      width: 100%;
    }

    @include breakpoint(sm) {
      justify-content: flex-start;
      padding-left: 20px;

      img {
        height: 43px;
        width: auto !important;
      }
    }
  }

  .search {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @include breakpoint(sm) {
      width: 100%;
      position: fixed;
      display: block;
      top: $header-height-mobile;
      left: 0;
    }
  }

  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  .mobileActions {
    display: flex;
    align-items: center;

    button {
      border: unset;
      background: transparent;
      padding: 0 5px;

      svg {
        height: 29px;
      }
    }
  }
}

.drawerInner {
  padding: 25px 24px 100px 44px;
  background-color: $color-menu-background;
  flex: 1;

  > div:first-child {
    button {
      width: 100%;
      padding: 0;

      > div {
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }
}
