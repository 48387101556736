@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/utilities';

.componentWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  header {
    width: 100%;
    height: $header-height-desktop;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1299;
    background-color: $color-white;
    padding-top: 50px;

    @include breakpoint(sm) {
      padding-top: 0;
      height: auto;
    }
  }

  .backToTop {
    position: fixed;
    bottom: 70px;
    right: 25px;
    background: $color-gray-background;
    color: $color-secondary;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;

    @include breakpoint(md) {
      bottom: 85px;
    }
  }
}
