@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  display: flex;

  @include breakpoint(md) {
    flex-direction: column;
  }
  @include breakpoint(sm) {
    display: block;
  }
}

.column {
  flex-basis: 66.66%;
  border-right: 1px dashed $color-gray-border;
  padding: 0 70px;

  &:first-child {
    flex-basis: 33.33%;
    padding: 0 70px 0 100px;

    @include breakpoint(sm) {
      padding: 0 !important;
    }
  }

  @include breakpoint(md) {
    width: 100%;
    border-right: none;
    padding: 0;
  }

  &:last-of-type {
    border-right: none;

    @include breakpoint(md) {
      border-top: 1px solid $color-gray-border;
      margin-top: 24px;
      padding-top: 24px;
    }

    @include breakpoint(sm) {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px dotted $color-gray-border;
    }
  }
}

.section {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  color: $color-text-dark;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 30px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 55px;

    @include breakpoint(sm) {
      font-size: 12px;
      height: auto;
      margin-bottom: 5px;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-wrap: wrap;

      @include breakpoint(sm) {
        height: auto;
        font-size: 12px;
      }

      &:last-child {
        > span {
          @include breakpoint(sm) {
            padding: 0;
          }
        }
      }

      svg {
        @include breakpoint(sm) {
          fill: $color-orange;
        }
      }
    }
  }

  button {
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    cursor: pointer;
    width: 150px;

    @include breakpoint(sm) {
      width: 100%;
    }
  }

  .label {
    display: flex;
    color: $color-text-light;
    white-space: nowrap;

    > span {
      color: $color-black;
      white-space: nowrap;
    }

    @include breakpoint(sm) {
      font-size: 12px;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.checkboxWrapper {
  margin-top: 16px;

  label > div {
    font-size: 16px;
    color: $color-text;
  }
}

.receiverOption {
  display: flex;
  align-items: flex-start;
  line-height: 1;
  padding: 4px 0;

  .current {
    color: $color-secondary;
  }
}
.customer {
  @include breakpoint(sm) {
    display: flex;
    column-gap: 15px;
    font-size: 12px;
  }

  > div:first-child {
    margin-bottom: 20px;
  }
}
.isSummary {
  .column {
    padding: 0 25px 0 0;
    flex-basis: unset;

    &:nth-child(2) {
      padding: 0 0 0 20px;
      border-right: unset;
      margin-right: 75px;
      @include breakpoint(sm) {
        margin: 10px 0 0;
        padding: 10px 0 0;
      }
    }

    .title {
      color: $color-text-dark;
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      height: unset;
      @include breakpoint(sm) {
        margin-bottom: 5px;
      }
    }
    .customer,
    .receiver {
      display: flex;
      flex-wrap: wrap;
      white-space: nowrap;

      @include breakpoint(sm) {
        column-gap: 15px;

        > div:first-child {
          margin-right: 0 !important;
        }
      }

      > div:first-child {
        margin-right: 20px;
        min-width: 90px;
      }
    }

    .section {
      font-size: 12px;
      position: relative;

      button {
        border: unset;
        background-color: transparent;
        color: $color-primary;
        width: unset;
        margin: 0;
        position: absolute;
        right: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
      }
    }
  }
}

.cashOnDelivery {
  margin-top: 20px;

  span {
    text-decoration: underline;
  }
}
