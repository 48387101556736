@import 'src/theme/color';

.wrapperComponent {
  border: 1px solid $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 12px;

  .cart {
    border: none;
    border-right: 1px solid $color-primary;
    background: transparent;
    color: $color-primary;
    cursor: pointer;
  }

  .dropdown {
    margin-right: 8px;

    svg {
      color: $color-primary;
    }
  }

  &.large {
    background: $color-primary;

    .cart {
      color: $color-white;

      svg {
        margin-left: 16px;
        font-size: 20px;
      }
    }

    .dropdown {
      svg {
        color: $color-white;
      }
    }
  }

  &:hover {
    background: $color-primary;

    .cart {
      color: $color-white;
    }

    .dropdown {
      svg {
        color: $color-white;
      }
    }
  }

  &.disabled {
    opacity: 0.7;

    .cart {
      cursor: auto;
    }

    .dropdown {
      svg {
        color: $color-gray-border;
      }
    }
  }

  .loader {
    position: absolute;
    > div {
      width: 35px;
    }
  }
}
