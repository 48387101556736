@import 'src/theme/color';

.wrapperComponent {
  display: flex;
  flex-direction: column;

  input {
    height: 30px;
    padding: 0 10px;
    font-size: 12px;
    border-radius: 0;
    border: 1px solid $color-gray-border;
    background: $color-white;
    color: $color-text-dark;
    outline: none;

    &:disabled {
      background-color: $color-gray-border;
      border-color: $color-gray-border;
    }

    &.error {
      border: 1px solid $color-error;
    }
  }
}
