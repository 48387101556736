@import 'src/theme/color';
@import 'src/theme/breakpoints';

@keyframes showSidebar {
  0% {
    width: 100%;
    background-color: transparent;
  }
  100% {
    width: 405px;
    background-color: $color-white;
  }
}

@keyframes showSliderSidebar {
  0% {
    width: 100%;
    background-color: transparent;
  }
  100% {
    width: 375px;
    background-color: $color-white;
  }
}

@keyframes showSidebarMd {
  0% {
    width: 100%;
    background-color: transparent;
  }
  100% {
    width: 320px;
    background-color: $color-white;
  }
}

@keyframes fadeInSidebar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shoppingListButtonWrapper {
  position: absolute;
  bottom: 25px;
  right: 0;
  display: block;
}

.wrapperComponent {
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 100%;
  border: 1px solid transparent;
  background-color: $color-white;
  align-items: center;
  flex-direction: column;

  &:not(.lineWrapper) {
    @media screen and (max-width: 900px) {
      max-width: 300px !important;
    }
  }

  .sidebarGallery {
    display: none;
    position: absolute;
    padding: 10px;
    right: 0;
    width: 25%;
    top: 0;
    height: 100%;
    z-index: 999;
    background: $color-white;

    img {
      margin: 6px auto 15px auto;
      display: block;
      cursor: pointer;
      width: 100%;
      max-width: 90px;
    }
  }

  &.withDynamicGallery:not(.lineWrapper) {
    align-items: flex-start;

    .product-image {
      max-width: 300px;
      width: 100%;

      img {
        @media screen and (min-width: 1250px) {
          width: 300px;
        }
      }
    }

    .product-details {
      flex: 1;
      max-width: 300px;
      width: 100%;
    }

    &:not(.isSlider) {
      &:hover {
        @media screen and (max-width: 1500px) and (min-width: 1200px) {
          animation: showSidebarMd ease 0.3s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        @media screen and (min-width: 1501px) {
          animation: showSidebar ease 0.3s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        .imageTitleWrapper,
        .product-image {
          z-index: 10;
        }

        .sidebarGallery {
          display: block;
          animation: fadeInSidebar ease 1s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }
    }

    &.isSlider {
      &:hover {
        @media screen and (max-width: 1500px) and (min-width: 1200px) {
          animation: showSidebarMd ease 0.3s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        @media screen and (min-width: 1501px) {
          animation: showSliderSidebar ease 0.3s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        .imageTitleWrapper,
        .product-image {
          z-index: 10;
        }

        .sidebarGallery {
          display: block;
          animation: fadeInSidebar ease 1s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  &.transparent {
    background-color: transparent;

    &:hover {
      box-shadow: 0 10px 10px -3px rgba(6, 25, 56, 0.15);
    }
  }

  &.lineWrapper:hover {
    box-shadow: unset;
    border-bottom: 1px solid $color-gray-border !important;
  }

  .product-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $color-text-light;
    width: 100%;

    .producer {
      height: 16px;
      margin-bottom: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      text-transform: uppercase;
    }

    .product-description {
      margin-bottom: 16px;
    }

    .product-params {
      ul {
        margin: 0 0 16px;
        padding: 0;
        list-style: none;

        li {
          display: inline-flex;
          margin-right: 12px;
          margin-bottom: 4px;

          .green {
            color: $color-success;
          }

          .red {
            color: $color-error;
          }
        }
      }

      button {
        border: none;
        background: transparent;
        font-size: 12px;
        color: $color-secondary;
        cursor: pointer;
        padding: 0;
      }
    }
  }

  &.lineWrapper {
    align-items: stretch;
    flex-direction: row;
    padding: 8px;
    border-bottom: 1px solid $color-gray-border;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  &:hover {
    z-index: 9;
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.25);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.25);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.25);
  }

  .imageTitleWrapper {
    width: 100%;
    display: flex;

    &.imageTitleWrapperLine {
      width: 20%;

      .labels {
        position: static;

        > span {
          padding: 3px 0;
          width: 100%;
          text-align: center;
          margin-right: 0;
          font-size: 11px;
          font-weight: 600;
        }
      }
    }
  }

  .imageStretch {
    width: auto;
    margin-right: -20px;
  }

  .product-image {
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      aspect-ratio: 0.658;
    }

    &.productImageMinimal {
      width: 50%;
      margin-right: 10px;
      height: auto;
    }

    margin-right: 0;

    &.productImageLine {
      margin-bottom: 0;
      margin-right: 47px;
    }
  }

  .labels {
    position: absolute;
    top: 0;
    left: 0;
  }

  .product-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    align-items: center;
    flex-direction: column;

    &.productDetailsLine {
      align-items: stretch;
      flex-direction: row;
      width: 100%;
      height: 100%;

      .product-title {
        span {
          margin-bottom: 0;
        }
      }
    }

    .product-title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: $color-text-light;

      width: 100%;

      &.productTitleLine {
        width: 40%;

        .producer {
          font-size: 14px;
          margin-bottom: 20px;
        }

        .title {
          max-width: 200px;
          margin-bottom: 20px;
          font-size: 14px;
          cursor: auto;
        }

        button {
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      span {
        margin-bottom: 4px;
      }

      .product-description {
        margin-bottom: 16px;
      }

      .product-params {
        ul {
          margin: 0 0 16px;
          padding: 0;
          list-style: none;

          li {
            display: inline-flex;
            margin-right: 12px;
            margin-bottom: 4px;

            .green {
              color: $color-success;
            }

            .red {
              color: $color-error;
            }
          }
        }

        button {
          border: none;
          background: transparent;
          font-size: 12px;
          color: $color-secondary;
          cursor: pointer;
          padding: 0;
        }
      }
    }

    .product-actions {
      position: relative;
      width: 100%;
      border-left: none;
      padding-left: 0;
      margin-left: 0;

      &.productActionsLine {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 60%;
      }

      .unit-selector-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 44px;

        margin: 0 20px;
        width: auto;

        &.unitSelectorWrapperMinimal {
          margin: 0 4px 0 0;
        }

        &.unitSelectorWrapperLine {
          margin: 0 8px;
          width: 200px;
        }

        > div {
          width: 100%;
        }

        [class$='-control'] {
          height: 44px;
        }
      }

      .availabilityWrapperLine {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $color-price;

        span {
          display: block;
          margin-bottom: 10px;
        }
      }

      .variantsWrapperLine {
        order: 2;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $color-price;

        span {
          margin-bottom: 10px;
        }
      }

      .shoppingListButtonWrapperLine {
        margin-left: 0;
        margin-right: 16px;
      }

      .priceWrapperLine {
        margin-top: 0;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        left: 0;
        font-size: 20px;
        text-align: right;

        .net {
          > span {
            > span {
              font-size: 14px;
            }
          }
        }
      }

      .netLine {
        flex-direction: column;
      }

      .toolsLine {
        margin-bottom: 0;
        position: absolute;
        display: flex;
        justify-content: flex-end;

        .counter-wrapper {
          text-align: right;
          max-width: 100px;
        }
      }
    }

    &.noActions {
      .productActionsLine {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 40px;
      }

      .unit-selector-wrapper {
        margin-bottom: 50px;

        &.unitSelectorWrapperLine {
          margin-bottom: 0;
        }
      }

      .counter-wrapper {
        .loginButton {
          text-decoration: none;
          color: $color-primary;
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          display: block;
          margin-bottom: 10px;

          small {
            font-weight: 400;
          }
        }
      }
    }
  }

  &.isSearch {
    padding: 5px 5px 5px 50px;
    border-bottom: 1px dashed $color-search;

    @include breakpoint(sm) {
      padding: 5px 20px 5px 0;
    }

    &:hover {
      border-bottom: 1px dashed $color-search;
      background-color: $color-gray;
    }

    .productActionsLine,
    .availabilityWrapperLine,
    .title {
      margin-bottom: 0 !important;
    }

    .netValue {
      font-weight: 700;
    }

    .product-image {
      width: unset !important;
      margin-right: unset;
    }

    .imageTitleWrapper {
      margin-right: unset;
      width: 14%;

      @include breakpoint(sm) {
        margin-right: 8px;
        width: 70px;
      }
    }

    .product-details {
      padding: 0;
      .product-title {
        .producer {
          font-size: 12px;

          @include breakpoint(sm) {
            font-size: 11px;
          }
        }

        .title {
          font-size: 10px;
          line-height: 140%;
          font-style: normal;
          font-weight: 400;
          max-width: 150px;
        }
      }

      .variants {
        margin-top: 12px;

        * {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 140%;
          color: $color-text-dark;
        }

        & > div {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;

          @include breakpoint(sm) {
            max-width: 180px;
          }
        }
      }

      .product-actions {
        flex-direction: row;
        align-items: center;

        @include breakpoint(sm) {
          justify-content: flex-end;
        }

        .priceWrapperLine {
          top: 50%;
          right: 70px;
          left: unset;
          transform: translateY(-50%);
          font-size: 14px;
          line-height: 120%;
          font-weight: 400;

          .net {
            color: $color-text-dark;

            span {
              > span {
                font-size: 14px;
              }
            }
          }

          .gross {
            font-size: 12px;
          }
        }
      }

      .loginButton {
        font-size: 12px !important;
      }
    }

    .shoppingListButtonWrapper {
      bottom: unset;
    }
  }

  &.isShoppingList {
    @include breakpoint(sm) {
      flex-wrap: wrap;
      position: relative;
      padding: 15px 10px 15px 30px;
    }
    &.lineWrapper {
      @include breakpoint(sm) {
        border-bottom: 0;

        border-top: 1px solid $color-gray-border;
      }
    }
    .imageTitleWrapper {
      @include breakpoint(sm) {
        width: 90px;
      }
    }
    .product-image {
      @include breakpoint(sm) {
        min-width: 90px;
      }
    }
    .productActionsLine {
      .variants-wrapper {
        @include breakpoint(sm) {
          order: -1;
          margin-bottom: 0;
          font-size: 12px;
        }
        > div > div {
          @include breakpoint(sm) {
            font-size: 12px;
          }
        }
      }
    }

    .product-details {
      @include breakpoint(sm) {
        width: calc(100% - 90px);
        padding: 0 10px;
      }
      .product-title {
        @include breakpoint(sm) {
          width: 100%;
        }
        .producer {
          @include breakpoint(sm) {
            font-size: 10px;
          }
        }
        .title {
          @include breakpoint(sm) {
            font-size: 12px;
            padding-right: 10px;
            line-height: 140%;
            margin-bottom: 2px;
          }
        }
      }
      &.productDetailsLine {
        @include breakpoint(sm) {
          flex-wrap: wrap;
        }
      }
      .product-actions {
        @include breakpoint(sm) {
          position: static;
        }

        &.productActionsLine {
          @include breakpoint(sm) {
            width: 100%;
          }
        }

        .variantsWrapperLine {
          span {
            @include breakpoint(sm) {
              margin-bottom: 5px;
            }
          }
        }
        .availabilityWrapperLine {
          @include breakpoint(sm) {
            margin-bottom: 0;
          }
          span {
            @include breakpoint(sm) {
              font-size: 10px;
            }
          }
        }
      }
    }
    .shoppingListButtonWrapper {
      @include breakpoint(sm) {
        position: absolute;
        top: 70px;
        right: -30px;
      }
    }

    > div:first-child {
      @include breakpoint(sm) {
        position: absolute;
        top: 60px;
        left: -20px;
      }
    }
  }
}
.shoppingListMobilePriceWrapper {
  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: $color-text-dark;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  > span {
    @include breakpoint(sm) {
      text-transform: uppercase;
    }
  }
  > div {
    @include breakpoint(sm) {
      width: calc(100% - 90px);
      padding-left: 20px;
      margin-bottom: 0;
    }
  }
}
.title {
  display: block;
  width: 100%;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $color-text-dark;
  cursor: pointer;
  text-decoration: none;
}

.text {
  width: 100%;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $color-text-light;

  strong {
    font-weight: 700;
  }
}

.priceWrapper {
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 8px;
}

.old {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 6px;
  font-weight: 400;
  text-decoration-line: line-through;
  color: $color-price;

  & + .net {
    color: $color-red;
  }
}

.net {
  display: inline-flex;
  flex-flow: wrap row;
  align-items: flex-end;
  color: $color-price;
}

.variants-wrapper {
  margin-bottom: 7px;
}

.tools {
  width: 100%;
}

.label {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 6px 10px;
  background: $color-primary;
  font-size: 12px;
  font-weight: 700;
  color: $color-white;

  &.new {
    background: $color-accent;
  }

  &.bestseller {
    background: $color-bestseller;
  }

  &.promotion {
    background: $color-violet;
  }
}

.removePosition {
  width: 20%;
  display: flex;
  justify-content: flex-end;

  button {
    border: unset;
    background-color: transparent;
  }
}
