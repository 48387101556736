@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  display: block;
  width: 100%;
}

.icon {
  display: inline-block;
}

.summary {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 34px;

  @include breakpoint(md) {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.summaryBox {
  position: relative;
  flex: 1 1 30%;
  height: 75px;
  padding: 16px;
  border: 1px solid $color-gray-border;
  background: $color-white;

  @include breakpoint(md) {
    margin: 0 16px;
    flex: 1;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.active {
      border: 2px solid $color-secondary;
    }
  }
}

.summaryCounter {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 32px;
  font-size: 40px;
  font-weight: 700;

  @include breakpoint(md) {
    position: static;
  }
}

.tabsHeader {
  display: flex;
  position: relative;
  gap: 10px;
  margin-bottom: 25px;

  @include breakpoint(md) {
    display: none;
  }
}

.tabsBox {
  flex: 1 1 30%;
  height: 45px;
  padding: 12px 16px;
  border-bottom: none;
  box-shadow: 0px -2px 5px rgba(6, 25, 56, 0.06);
  border-radius: 4px 4px 0px 0px;
  background: $color-tab-bg;
  border: 1px solid $color-tab-border;
  cursor: pointer;

  .summaryName {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $color-label;

    @include breakpoint(md) {
      color: $color-primary;
    }
  }

  &.active {
    border: none;
    background: $color-white;
    border-color: $color-white;

    .summaryName {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: $color-text;
    }
  }
}

.tableWrapper {
  margin-bottom: 50px;
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
  > div:nth-child(2) {
    @include breakpoint(sm) {
      padding: 10px 0;
    }
  }
  > div > div {
    @include breakpoint(sm) {
      margin-bottom: 0;
      border-bottom: 1px solid $color-gray-border;
    }
    > div:first-child {
      > div > div {
        @include breakpoint(sm) {
          padding: 5px 0;

          svg {
            @include breakpoint(sm) {
              fill: $color-text-light;
            }
          }
        }

        > div {
          @include breakpoint(sm) {
            font-size: 12px;
            max-width: unset;
            margin-left: 0;
            font-weight: 600;
            text-transform: unset;
          }
          > span {
            @include breakpoint(sm) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  tr td {
    position: relative;

    > span {
      height: 50px;
    }
  }

  tr:nth-child(2n) {
    td {
      background-color: #efeae84a;
    }
  }

  th {
    padding: 5px 18px;
    background-color: $color-white;
    border-bottom: 1px solid $color-summary-border;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }

  td {
    padding: 0 18px;
  }

  .expanded-row {
    td {
      border-right: none;
    }
  }
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;

  @include breakpoint(sm) {
    font-size: 10px;
    padding: 5px 0;
  }

  svg {
    @include breakpoint(sm) {
      max-width: 15px;
    }
  }
}

.documentsCell {
  display: block;
  max-height: 30px;
  overflow-y: auto;
  padding: 10px 0;
  box-sizing: content-box;
  width: 180px;
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a69797;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &.hasDocument {
    &:hover {
      position: absolute;
      top: 0;
      left: 18px;
      height: 100px;
      max-height: 100px;
      z-index: 999;
      background-color: $color-white;
      width: 180px;
      -webkit-box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.4);
      box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.4);
    }
  }
}

.status {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
  }

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
  }
  button {
    background-color: transparent;
    border: unset;
    display: flex;
    align-items: center;
    text-decoration: underline;

    @include breakpoint(sm) {
      margin: 0;
    }
  }

  svg,
  img {
    margin-right: 11px;
    @include breakpoint(sm) {
      max-width: 10px;
      margin-right: 2px;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.repay {
  cursor: pointer;
  position: relative;

  svg {
    width: 20px;
  }
}

.mobileListItem {
  margin-bottom: 8px;
  background: $color-white;

  .itemHeader {
    padding: 16px;
    color: $color-primary;
    border-bottom: 1px solid $color-gray-border;
  }

  .itemBody {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    > div {
      border-right: 1px solid $color-gray-border;
      padding: 0 16px;
      text-align: center;
      flex: 1;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
        border-right: none;
      }
    }
  }

  .label {
    color: $color-text-light;
    display: block;
  }
}

.documentNumber {
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(sm) {
    width: auto;
    display: inline-flex;
    margin-left: 35px;
  }
  svg {
    margin-left: 10px;
    height: 19px;
    cursor: pointer;
  }
}

.hasDocumentsFiles {
  cursor: pointer;
}

.daysExpired {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: $color-red;
}

.filtersWrapper {
  display: flex;
  margin: 38px 0;
  align-items: center;
  @include breakpoint(sm) {
    display: block;
    margin: 25px 0 15px;
  }

  .date {
    margin-left: 20px;
    @include breakpoint(sm) {
      margin-left: 0;
      margin-top: 20px;
    }
    button {
      @include breakpoint(sm) {
        width: 100%;
      }
    }
  }

  .filtersInner {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.hasCheckbox {
      justify-content: space-between;
      padding-left: 30px;

      svg path {
        fill: $color-primary;
      }
    }

    .clearFilters {
      color: $color-red;
      border: unset;
      background-color: transparent;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.paperBox {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: $color-white;
  padding: 20px 25px;
}

.unpaid {
  color: $color-red;
}

.paid {
  color: $color-affirmative;
}

.mobileTabsWrapper {
  > div {
    @include breakpoint(sm) {
      margin-bottom: 0;
    }
    > div:first-child > div > div {
      @include breakpoint(sm) {
        border: 1px solid $color-black;
        padding: 10px 17px;
        border-radius: 4px;
      }
      > div {
        @include breakpoint(sm) {
          text-transform: none;
          font-size: 14px;
          font-weight: 700;
          max-width: unset;
          justify-content: flex-start;
          width: auto;
        }
        &:first-child {
          @include breakpoint(sm) {
            min-width: 100px;
            color: $color-text;
          }
        }
      }
    }

    > div:nth-child(2) {
      @include breakpoint(sm) {
        border-top: 1px solid $color-gray-border;
        margin: 10px 14px 0;
      }
    }
  }
}

.popoverContent {
  padding: 20px 25px;
}
